.boxSubentities {
  background-color: $theme-lightgray;
  padding-bottom: 2rem;
  h3 {
    font-family: $theme-font-josefin;
    color: $theme-font-base-color;
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom:2rem;
    @include media-breakpoint-down(sm) {
      font-size: 1.3rem;
    }
  }
  &.related {   
    padding-top: 3rem;
  }
  &__item {
    min-height: 280px;
    margin-bottom:1rem;
    &__in {
      min-height: 280px;
      overflow: hidden;
      position:relative;
      margin:0 auto;
      &__overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        transition: all 0.2s ease;   
      }
      @extend .flex_full_center;
      &:before {
        @extend .hoverize_this_1;
      }
      &:hover:before {
        transform: translateX(0);
      }
    }
    &__title {
      font-size: 2.4rem;
      line-height: 2.4rem;
      color: $theme-white;
      z-index: 5;
      font-family: $theme-font-josefin;
      text-align: center;
      &:hover {
        color: $theme-white;
        text-decoration: none;
      }
       @include media-breakpoint-down(sm) {
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }
    .material-icons {
      width: 50px;
      font-size: 3rem;
      color: $theme-white;
      text-align: center;
      margin-top:1.6rem;
      z-index: 5;
      @include media-breakpoint-down(sm) {
        font-size: 2.4rem;
        margin-top: 1rem;
      }
    }
  }
}