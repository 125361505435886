.homeDiscover {
  background-color: $theme-lightgray;
  min-height: 300px;
  margin: 10px 10px 0 10px;
  .discover_more {
    color: $theme-bluette !important;
  }
  h2 {
    color: #6a737b;
    font-weight: $theme-font-weight-bold;
    letter-spacing: 0.5rem;
  }
  h4 {
    color: #6a737b;
    font-size: 1.3rem;
    letter-spacing: 0.3rem;
    @include media-breakpoint-down(sm) {
      font-size: 1.1rem;
    } 
  }
  h6 {
    color: #3f454b;
    font-size: 0.9rem;
    letter-spacing: 0.3rem;
  }
  &__features {
    h2 {
      color: $theme-green-dark;
    }
  }
}