$theme-white: #fff;
$theme-black: #000;
$theme-lightgray: #f1f1f2;
$theme-gray: #6a737b;
$theme-green-superlight: #f0f2ec;
$theme-green-light: #dbe0bb;
$theme-green-medium: #a5b254;
$theme-green-dark: #617e54;
$theme-menu-background: rgb(105,129,61);
$theme-gold: #d2b155;
$theme-bluette: #8290ae;
$theme-brown: #826a62;

// Fonts
$theme-font-base-size: 15px;
$theme-font-base-color: #3f454b;
$theme-font-base-family: "Open sans", Helvetica, sans-serif;
$slick-font-path: "../fonts/" !default;

$theme-font-josefin: "Josefin Sans", Helvetica, sans-serif;

$theme-font-weight-light: 300;
$theme-font-weight-normal:400;
$theme-font-weight-bold:  700;


// Easings
$mm-easing-back:cubic-bezier(0.68, -0.55, 0.265, 1.55);
$mm-easing-bounce:cubic-bezier(0, 1.8, 1, 1.8);
$mm-easing-out:cubic-bezier(.41,.49,.57,1);

// Social
$theme-fb-color:#3a589b;
$theme-tw-color:#568bc9;
$theme-gp-color:#d6492f;
$theme-pt-color:#ca4638;
$theme-yt-color:#C3181E;
$theme-rss-color:#e19126;

// sizing
$visual-width: calc(100% - 20px); /* 10px passpartout */
$visual-height: calc(100vh - 20px); /* 10px passpartout */
$gallery-height: 60vh;
$visual-height-mobile: calc(70vh - 20px); /* 10px passpartout */