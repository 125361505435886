.multimedia {
  &__filters {
    list-style: none;
    display: flex;
    justify-content: center;
    text-align: center;
    padding:2rem 0 2rem 0;
    li {
      margin:10px;
      text-align: center;
      text-transform:uppercase;
    }
  }
  &__item {
    min-height: 300px;
    span {
      position:absolute;
      right: 15px;
      top:0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70px;
      height: 70px;
      background-color: rgba(0,0,0,0.5);
      svg {
        fill: $theme-white;
        width: 30px;
        height: 30px;
        opacity: 0.8;
      }
    }
    img {
      width: 100%;
    }
  }
}