@import "variables";

.overlay-social-share{
  &__wrap{
    margin:-9px -14px;
    overflow: hidden;
  }
  &__link{
    display: block;
    float: left;
    width: 50px;
    height: 50px;
    background: #333;
    position: relative;
    opacity: 0;
    transform:translate(0,-10px);
    &:nth-child(1){
      transition: all 0.2s ease-in-out 0s;
    }
    &:nth-child(2){
      transition: all 0.2s ease-in-out .2s;
    }
    &:nth-child(3){
      transition: all 0.2s ease-in-out .4s;
    }
    .buttons-on &{
      opacity: 1;
      transform:translate(0,0);
    }
    &>.svgIcon{
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform:translate(-50%,-50%);
      fill:#fff;
    }
    &.fb{
      background: $theme-fb-color;
    }
    &.gp{
      background: $theme-gp-color;
    }
    &.tw{
      background: $theme-tw-color;
    }
  }
}