html {
  font-size: $theme-font-base-size;
  color: $theme-font-base-color;
}

body {
  font-size: rem(15);
  font-family: $theme-font-base-family;
  color: $theme-black;
  background: $theme-white;
  &.menu_open {
    overflow-y: hidden;
  }
}
img { max-width: 100%; }
p,a {
  font-size: $theme-font-base-size;
  color: $theme-font-base-color;
}
.container-fluid-down-lg {
  @include media-breakpoint-down(lg) {
    max-width: 100%;
  }
}

.container-fluid-down-md {
  @include media-breakpoint-down(md) {
    max-width: 100%;
  }
}

#barba-wrapper {
  min-height: 300px;
  &.loading {
    background: url(/dist/media/images/preload.gif) no-repeat center 150px;
  }
}
.container-fluid {
  max-width: 1400px;
}
.topSpacer {
  height: 70px;
}
.blueimp-gallery > .next,
.blueimp-gallery > .prev {
  color: $theme-white !important;
}
#privacy {
    left: 3px;
    top: 5px;
    opacity: 1;
}