.flex_full_center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.hoverize_this_1 {
  display: block;
  content:'';
  position: absolute;
  width: 100%;
  height: 100%;
  left:0;
  background-color: rgba(0,0,0,0.2);
  transition: all 0.2s ease;
  transform: translateX(-100%);
}
@mixin img_placeholder($w:100%, $h:100%, $bgcolor:gray, $icon:'') {
  width: $w;
  height: $h;
  background-image: url($icon);
  background-repeat: no-repeat;
  background-color: $bgcolor;
  background-position: center center;
  background-size: auto;
}
.img_placeholder {
  @include img_placeholder(100%,300px,$theme-green-light,'/images/img-placeholder.png');
}
.bg_white {
  background-color: $theme-white;
}
.bg_lightgray {
  background-color: $theme-lightgray;
}