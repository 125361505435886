 /* titles sizing */
h2 {
  font-size: 2.5rem;
  font-weight: $theme-font-weight-bold;
  letter-spacing: 0.5rem;
   @include media-breakpoint-down(sm) {
    font-size: 1.5rem;
  } 
}
h4 {
  font-size: 1.3rem;
  letter-spacing: 0.3rem; 
  @include media-breakpoint-down(sm) {
    font-size: 1.1rem;
  } 
}
h6 {
  font-size: 1.1rem;
  letter-spacing: 0.3rem; 
}
.text-opensans {
  font-family: $theme-font-base-family;
}
.text-josefin {
  font-family: $theme-font-josefin;
}
.text-light {
  font-weight: $theme-font-weight-light;
}
.text-normal {
  font-weight: $theme-font-weight-normal;
}
.text-bold {
  font-weight: $theme-font-weight-bold;
}
.discover_more {
  font-size: 0.9rem;
  text-transform: uppercase;
  display: inline-block;
  height: 30px;
  .material-icons {
    font-size: 0.9rem;
    color: inherit;
    margin:0 0 0 5px;
    width: 20px;
    transform: translateY(2px);
  }
  &:hover {
    opacity:0.5;
    text-decoration: none;
  }
}