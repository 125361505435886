$burger: 'topBar__burger';
.topBar {
  width: 100%;
  z-index: 101;
  position:fixed;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  transition: all 0.3s ease;
  .sticked & {
    background-color: $theme-white;
    padding-bottom: 0;
    top:0;
  }
  .menu_open & {
    background-color: transparent;
    padding-bottom: 0;
    top:10px;
  }
  @include media-breakpoint-down(sm) {
    padding:0;
  }
  &__leftbox {
    flex-basis: 30%;
  }
  &__middlebox {
    display: flex;
    flex-basis: 40%;
    @include media-breakpoint-down(sm) {
      padding-left: 5px;
      margin-right: -5px;
    }
  }
  &__rightbox {
    display: flex;
    justify-content: flex-end;
    flex-basis: 30%;
  }
  &__burger {
    @extend .flex_full_center;
    font-size: 0.8rem;
    width: 75px;
    height: 75px;
    background-color: $theme-lightgray;
    transition: all 0.3s ease;
    position:relative;
    cursor: pointer;
    overflow: hidden;
    .sticked & {
      background-color: $theme-green-dark;
      color:$theme-white;
    }

    .menu_open & {
      background-color: $theme-green-superlight;
    }
    &__text {
      margin-bottom: 5px;
      z-index: 101;
    }
    &__icon {
      display: block;
      width: 32px;
      margin-bottom: 5px;
      z-index: 101;
      .menu_open & {
        margin-top: 5px;
      }
    }
    &__line {
      display: block;
      width: 32px;
      height: 1px;
      background-color: $theme-font-base-color;
      .sticked & {
        background-color: $theme-white;
      }
      &:nth-child(1) {
        margin-bottom:8px;
        transform-origin: 50% 50%;
        .menu_open & {
          transform:rotate(45deg) translate(3px,4px);
        }
      }
      &:nth-child(2) {
        margin-bottom:8px;
        transform-origin: 50% 50%;
        .menu_open & {
          transform:rotate(-45deg) translate(1px,-4px);
        }
      }
      &:nth-child(3) {
        .menu_open & {
          display:none;
        }
      }
    }
    &:before {
      @extend .hoverize_this_1;
    }
    &:hover:before {
      transform: translateX(0);
    }
  }
  &__services {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 75px;
    padding: 0 30px 15px 0;
    color: $theme-white;
    text-align: center;
    @include media-breakpoint-down(sm) {
      padding: 0 15px;
      align-items: center;    
    }
    .sticked & {
      color:$theme-gray;
      align-items: center;
    }
    .SVG__camera {
      margin-right:2rem;
      @include media-breakpoint-down(sm) {
        margin-right:5px;
      }
      &--c0 {
        fill:$theme-white;
        .sticked & {
          fill:$theme-gray;
        }
      }
    }
    .SVG__phone {
      margin-left:2rem;
      @include media-breakpoint-down(sm) {
        margin-left:5px;
      }
      &--c0 {
        fill:$theme-white;
        .sticked & {
          fill:$theme-gray;
        }
      }
    }
    .SVG__chevron {
      transform: rotate(90deg);
      width: 100%;
      height: 14px;
      &--c0 {
        fill:$theme-white;
        .sticked & {
          fill:$theme-gray;
        }
      }
    }
    &__lang {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-transform: uppercase;
      text-align: center;
      position:relative;
      width: 30px;
      a {
        color: $theme-white;
        font-size: 0.8rem;
        .sticked & {
          color: $theme-font-base-color;
        }
      }
      &__current {
        width: 100%;
        text-align: center;
      }
       &__others__clipper {
        width: 30px;    
        height: 30px;    
        overflow: hidden;
        position:absolute;
        top:20px;
        .lang__open & {
          height: auto;
        }
      }
      &__others {
        width: 30px;
        padding: 5px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: auto;
        transition: all 0.3s ease;
        transform: translateY(-100%);
        margin-top:20px;
        .lang__open & {
          margin-top:0;
          transform: translateY(0);
          .sticked & {
            background: rgb(167,167,167);
            background: linear-gradient(0deg, rgba(167,167,167,0.5) 0%, rgba(255,255,255,1) 100%);
          }
        }
      }
    }
  }
  &__request {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    height: 75px;
    padding: 0 2rem;
    background-color: $theme-lightgray;
    transition: all 0.3s ease;
    cursor: pointer;
    position:relative;
    overflow: hidden;
    @include media-breakpoint-down(sm) {
      padding: 0 1.5rem;
    }
    .sticked & {
      background-color: $theme-gray;
      padding: 0 1.5rem;
    }
    .menu_open & {
      background-color: $theme-green-superlight;
    }
    &__text {
      font-size:1rem;
      text-transform: uppercase;
      line-height: 1.2rem;
      z-index: 101;
      color:$theme-gray;
      .sticked & {
        display: none;
      }
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
    .SVG__chevron {
      width: 10px;
      height: 10px;
      margin-left: 10px;
      z-index: 101;
      .sticked & {
        display: none;
      }
      @include media-breakpoint-down(sm) {
        display: none;
      }
      &--c0 {
        stroke:$theme-gray;
      }
    }
    .SVG__envelope {
      z-index: 101;
      display: none;
      .sticked & {
        display: block;
      }
      @include media-breakpoint-down(sm) {
        display: block;
      }
      &--c0 {
        fill:$theme-gray;
        .sticked & {
          fill:$theme-white;
        }
      }
    }
    &:before {
      @extend .hoverize_this_1;
    }
    &:hover:before {
      transform: translateX(0);
    }
  }
  &__logo {
    margin:10px auto 0 auto;
    @include media-breakpoint-down(sm) {
      margin:0 auto 0 auto;
    }
    .sticked & {
      margin:3px auto 0 auto;
    }
  }
  .SVG__logo {
    z-index: 101;
    .sticked & {
      height:70px;
      margin:3px auto 0 auto;
      display: none;
    }
    @include media-breakpoint-down(sm) {
      display: none;
    }
    &--c0 {
      stroke:$theme-white;
      fill:transparent;
      .sticked & {
        display: none;
      }
    }
    &--c1 {
      fill:$theme-white;
      .sticked & {
        fill:$theme-lightgray;
      }
    }
    &--c2 {
      fill:$theme-white;
      .sticked & {
        display: none;
      }
    }
    &--c3 {
      fill:$theme-white;
      .sticked & {
        display: none;
      }
    }
  }
  .SVG__logo__php {
    margin:10px auto 0 auto;
    z-index: 101;
    display: none;
    &--c0 {
      fill: $theme-gold;
    }
    .sticked & {
      display: block;
      height:70px;
      margin:0 auto 0 auto;
    }
    @include media-breakpoint-down(sm) {
      display: block;
      width: 50px;
      height: 50px;
      margin:17px auto 0 auto;
    }
  }
}