.SVG {
  &__facebook {
    width: 30px;
    height: 30px;
    &--c0 {
      fill:#3A559F;
    }
  }
  &__tripadvisor {
    width: 30px;
    height: 30px;
    &--c0 {
      fill:#589541;
    }
  }
}