.homeEraclea {
  min-height: 400px;
  margin:10px;
  display: flex;
  justify-content: center;
  padding-top: 3rem;
    @include media-breakpoint-down(sm) {
      padding-top: 6rem;
    }
  h2 {
    color: #5f686d;
    font-weight: $theme-font-weight-bold;
    font-size: 2.5rem;
    letter-spacing: 0.5rem;
    @include media-breakpoint-down(sm) {
      font-size: 1.5rem;
    }
  }
  h4 {
    font-family: $theme-font-josefin;
    color: $theme-font-base-color;
    font-size: 1.3rem;
     @include media-breakpoint-down(sm) {
      font-size: 1.1rem;
    }  
  }
  h6 {
    font-size: 1.1rem;
  }
  .discover_more {
    color: $theme-font-base-color;
    .material-icons {
      font-size: 2rem;
    }
  }
}