.AjaxForm .form-control,
.AjaxForm .form-group,
.AjaxForm .item,
.transition-all {
  -webkit-transition: all 0.6s;
  -ms-transition: all 0.6s;
  -o-transition: all 0.6s;
  transition: all 0.6s;
}

.AjaxForm .form-control,
.AjaxForm .form-group,
.AjaxForm .item,
.transition-all {
  -webkit-transition: all 0.6s;
  -ms-transition: all 0.6s;
  -o-transition: all 0.6s;
  transition: all 0.6s;
}

.AjaxForm {
  // background-position: center;
  // background-repeat: no-repeat;
  // // background-image: url(/images/__wd__/preload.gif);
  // form {
  // 	float: left;
  // 	width: 100%;
  // 	position: relative;
  // 	div[class*=col] {
  // 		background: #fff;
  // 	}
  // }
  background-position:center 150px;
  &,
  form {
    transition: all .2s ease-in-out;
  }

  &.loading {
    background: url(/modules/cms_minimal/images/lazyLoader.gif) no-repeat center 150px;
    form {
      opacity: .3;
      &,
      & * {
        pointer-events: none !important;
      }
    }
  }
  .success,
  .errors {
    position: absolute;
    top: -21px;
    right: 5%;
    left: 5%;
    padding: 7px;
    color: #1b1b1b;
    cursor: pointer;
    opacity: 1 !important;
    z-index: 10;
    &:after {
      border: 0 none;
      border-radius: 50%;
      color: #fff;
      content: "x";
      display: block;
      font-size: 24px;
      height: 30px;
      line-height: 26px;
      position: absolute;
      right: -16px;
      text-align: center;
      top: -16px;
      width: 30px;
    }
  }
  .success {
    border: 1px solid #0c0;
    &:after {
      background-color: #0c0;
    }
  }
  .errors {
    border: 1px solid #d00;
    &:after {
      background-color: #d00;
    }
  }
  &.glyphicon {
    .success,
    .errors {
      &:after {
        content: "\e014" !important;
      }
    }
  }
  &.fa {
    .success,
    .errors {
      &:after {
        content: "\f00d" !important;
      }
    }
  }
  .btn-primary {
    background-color: $theme-gold;
    border-color: $theme-gold;
    &:hover { background-color: darken($theme-gold, 5%); }
  }
}

.form-group.citta_provincia input.citta {
  float: left;
  width: 65%;
}

.form-group.citta_provincia input.prov {
  float: left;
  margin-left: 1%;
  width: 34%;
  text-align: center;
}

.form-group label {
  font-weight: normal;
}

.sweet-alert {
  .lead {
    font-size: 1.1em;
  }
}

.hasCalendar {
  background-image: url(/images/svg/calendar.svg);  
  background-repeat: no-repeat;
  background-position: 95%;
  background-color: #ffffff!important;
  cursor: pointer;
}

// DATEPICKER CUSTOM
$abs-int-booking-COLOR:$theme-green-dark;
$abs-int-booking-BACKGROUND-hilight:$theme-gold;
#ui-datepicker-div{
	width: 290px;
	// Larghezza doppio calendario
	&.ui-datepicker-multi-2{
		width: 590px !important;
	}
	border-radius: 0;
	background: #fff;
	.ui-widget-header{
		background: transparent;
		border: 0 none;
		color:$abs-int-booking-COLOR;
	}
	th{
		font-size:.8em;
		text-transform: uppercase;
	}
	td{
		a,
		span{
			text-align: center;
			width: 38px;
			height: 38px;
			padding-top: 9px;
			background: none;
			color: #fff;
			border: 1px solid #fff;
		}
	}
	// Date disabilitate
	.ui-state-disabled .ui-state-default{
		background: rgba(#000,50%);
		color: #fff;
		&:hover{
			background: rgba(#000,50%);
		}
	}
	// Date attive
	.ui-state-default, 
	.ui-widget-content .ui-state-default, 
	.ui-widget-header .ui-state-default{
		background: $abs-int-booking-COLOR;
		&:hover{
			background: $abs-int-booking-BACKGROUND-hilight;
		}
	}
	// Data selezionata
	.ui-state-active, 
	.ui-widget-content .ui-state-active, 
	.ui-widget-header .ui-state-active{
		background:$abs-int-booking-BACKGROUND-hilight;
	}
	// Checkin selezionato
	.datepickerCheckinDay > span{
		background: rgba(#000,80%);
	}
	// Frecce
	.ui-widget-header {
		.ui-icon{
			color: $abs-int-booking-COLOR;
			text-indent: 0;
			cursor: pointer;
		}
		.ui-state-hover{
				background: transparent;
				border: 0 none;
			}
		.ui-datepicker-next span{
			//top: 8px;
			// &:extend(.glyphicon, .glyphicon-chevron-right all);

		}
		.ui-datepicker-prev span{
			//top: 8px;
			// &:extend(.glyphicon, .glyphicon-chevron-left all);
		}
		.ui-datepicker-prev-hover, 
		.ui-datepicker-next-hover{
			//top: 0;
		}
	}	
}