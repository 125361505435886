.homeNavSections {
  min-height: 300px;
  margin: 10px 10px 0 10px;
  h2 {
    color: $theme-white;
    font-weight: $theme-font-weight-bold;
    font-size: 2.5rem;
    letter-spacing: 0.5rem;
    @include media-breakpoint-down(sm) {
      font-size: 1.5rem;
    }
  }
  h4 {
    font-family: $theme-font-josefin;
    color: $theme-white;
    font-size: 1.3rem;
    letter-spacing: 0.3rem;
    @include media-breakpoint-down(sm) {
      font-size: 1.1rem;
    } 
  }
  h6 {
    font-family: $theme-font-josefin;
    color: $theme-white;
    font-size: 1.1rem;
    letter-spacing: 0.3rem;
  }
  &__list {
    @include media-breakpoint-down(sm) {
      margin-top: 0!important;
    } 
  }
  &__item {
    $itemclass: &; 
    position: relative;
    &__in {
      width: 240px;
      height: 240px;
      position: relative;
      overflow: hidden;
      margin:0 auto;
      @extend .flex_full_center;
      background-color: $theme-white;
      &__overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.1);
        transform: translate(-100%,-0); 
        transition: all 0.2s ease;   
      }
      .material-icons {
        width: 20px;
        color: $theme-brown;
      }
      &:before {
        @extend .hoverize_this_1;
      }
      &:hover:before {
        transform: translateX(0);
      }
    }
    &__icon {
      svg {
        width: 50px;
        height: 50px;
        fill: $theme-brown;
      }
    }
    &__title {
      color: $theme-brown;
      display: block;
      z-index: 6;
      font-family: $theme-font-josefin;
      text-align: center;
      font-size: 22px;
      &:hover {
        color: $theme-brown;
        text-decoration: none;
      }
    }
  }
}