.homeGalleryGo {
  min-height: 500px;
  margin:10px;
  @extend .flex_full_center;
  h2 {
    color: $theme-white;
    font-weight: $theme-font-weight-bold;
    font-size: 24px;
    letter-spacing: 0.3rem;
    @include media-breakpoint-down(sm) {
      font-size: 1.5rem;
    }
  }
  h4 {
    font-family: $theme-font-josefin;
    color: $theme-white;
    font-size: 24px;
    letter-spacing: 0.3rem;
    @include media-breakpoint-down(sm) {
      font-size: 1.1rem;
    } 
  }
  h6 {
    font-size: 1.1rem;
    letter-spacing: 0.3rem;
  }
  a {
    color: $theme-white;
    &.discover_more { 
      font-size: 24px;
      .material-icons { font-size: 24px; }
    }
  }
  svg { 
    fill: $theme-white; 
    width: 135px;
    height: 135px;
    @include media-breakpoint-down(xs) {
      width: 50px;
      height: 50px;
    }
  }
}