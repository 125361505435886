.footer {
  padding-top: 2rem;
  padding-bottom: 2rem;
  img { margin: 0 15px 15px 0; }
  p {
    line-height: 0.8rem;
    @include media-breakpoint-down(md) {
          line-height: 1.6rem;
    }
  }
  &__data {
    margin-top:2rem;
  }
  &__social {
    margin-top:1.5rem;
  }
  &__menu {
    list-style: none;
    display: flex;
    justify-content: center;
    padding:0;
    margin-top:1rem;
    li {
      margin: 5px;
      a {
        font-size: 0.9rem;
      }
    }
  }
}