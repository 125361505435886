#serp {
	.listFind {
		padding: 0 15px;
		ul { 
			padding: 0; 
			ul { padding: 0 15px; }
		}
		li { 
			list-style: none;
			li { 
				list-style: decimal-leading-zero inside none; color: #444444; text-decoration: none; margin-bottom: 20px;
				span { color: #444444; }
				small { color: #666666 }
				em { text-decoration: underline; color: #666666; display: block; clear: both;}
			}
		}
		big { color: #444444;}
		small:empty, 
		small:empty+br {display: none;}
	}
	.findTitle { 
		padding: 0 15px;
	}
}
.search-inpage {
	.fa {color: #cccccc;font-size: 18px;position: absolute;right: 7px;top: 4px;}
	label {margin: 0;position: relative;width: 50%;}
	input {background-color: rgba(0, 0, 0, 0);border: 1px solid #666666;padding: 7px 14px;position: relative;width: 100%;z-index: 1;}
	button {margin-left: 21px; background-color: #cccccc; color: #666666; border: 1px solid #444444; background-image: none;}
}