$aspect_padding: 10px;
.mm-form-search {
	// preset
	padding: $aspect_padding $aspect_padding 0;
	margin: 0;
	// bottoni 
	.mm-button {
		float: right;
		margin-left: $aspect_padding;
		display: none;
		&.mm-text{}
		&.mm-graph{}
		&.mm-graph-over{
			text-align: center;
			.mm-icon {
				margin: 0;
			}
		}
	}
	// icone 
	.mm-icon {
		float: left;
		margin-right: $aspect_padding;
		display: none;
		&.mm-pre{}
		&.mm-post{}
		&.mm-inside{}
	}
	//
	// setup bottoni per modello
	&.mm-basic {
		.mm-button {
			&.mm-text {
				margin-left: 0;
				display: inline-block;
			}
		}
	}
	&.mm-essential {
		&.mm-fit {
			margin: 0px;
			.mm-button {
				&.mm-graph-over {
					margin-left: 0;
					float: none;
					display: block;
					.mm-icon {
						margin-left: 0;
						float: none;
						display: block;						
					}
				}
			}
		}
	}
	&.mm-inpage {
		width: 100%;
		.form-group {
			width: 100%;
			.input-group {
				width: 70%;
				float: left;
			}
			.mm-text {
				display: block;
				width: 20%;
				float: left;
			}
		}
	}
}
