pre{
    position:fixed;
    left:0;
    top:0;
    text-align: left;
    text-transform: none;
    width:325px;
    height:100%;
    background:#fff;
    overflow-y:auto;
    line-height: 11px;
    font-size: 11px;
    letter-spacing: 0;
    word-spacing: 0;
    z-index:999999999999999;
    padding: 10px;
    margin: 0;
    word-break:normal;
    word-wrap:normal;
    font-family: arial;
    -webkit-box-sizing: content-box !important;
    -moz-box-sizing: content-box !important;
    box-sizing: content-box !important;
    overflow-y:auto;
}
.pointer{
  cursor:pointer;
}
.col-tw{
  width:20%;
  float: left;
  margin:0;
}
.unboxed *,
.unboxed *:after, 
.unboxed *:before{
    box-sizing: content-box !important;
}
.anim,
.anim:before,
.anim:after{
  transition: all 0.2s ease-in-out;
}
.anim-back,
.anim-back:before,
.anim-back:after{
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.mm-spin {
  -webkit-animation: mm-spin 2s infinite linear;
  animation: mm-spin 2s infinite linear;
}
+-webkit-keyframes(mm-spin){
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
+keyframes(mm-spin) {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@mixin bg-set-contain{
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

@mixin bg-set-cover{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/*
Permette di settare un delay incrementale per le transitions
utile per animazioni con effetto a "ventaglio" full css 
es. voci di menù
*/

@mixin transitionDelaySteps($number, $factor:.1s) {
  @for $i from 0 through $number {
    &:nth-child(#{$i}) {
      transition-delay: ($i*$factor);
    }
  }
}


/*
Genera modificatori elementi
*/
@mixin colorModifiers($list, $properties: 'color') {
  @each $key,
  $color in $list {
    &--#{$key} {
      @each $prop in $properties {
        #{$prop}:#{$color};
      }
    }
  }
}

// Lazy load immagini di sfondo
[data-lazy]{
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transition: opacity 0.2s ease-in-out;
  &.withLoader{  
    &:after{
      transition: all 0.2s ease-in-out;
      content:'';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background: #e3e3e3 url(/modules/cms_minimal/images/lazyLoader.gif) no-repeat center center;
      z-index: 1;
      opacity: 1;
      left:0;
      top: 0;
    }
    &.loaded{
      &:after{
        opacity: 0;
      }
    }
  }
}
 
.lazy-loader-bar{
  position: relative;
  &:before{
    content:'';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    max-width: 300px;
    height: 1px;
    background:fade(#000,40%);
    animation:lazy-loader-animation 1s infinite alternate;
    transition: all 0.2s ease-in-out;
    z-index: 1;
    opacity: 1;
    .loaded &{
      opacity: 0;
      animation:none;
    }
  }
}
 
// Animazione loader
@keyframes lazy-loader-animation{
  0% {transform: translate(-50%,-50%) scaleX(0);}
  100% {transform: translate(-50%,-50%) scaleX(1);}
}