.boxNewsletter {
  min-height: 400px;
  margin:10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: $theme-lightgray;
  h2 {
    color: $theme-white;
    font-weight: $theme-font-weight-bold;
    font-size: 2.5rem;
    letter-spacing: 0.5rem;
    @include media-breakpoint-down(sm) {
          font-size: 1.5rem;
      }
  }
  h4 {
    font-family: $theme-font-josefin;
    color: $theme-white;
    font-size: 1.3rem;
     @include media-breakpoint-down(sm) {
    font-size: 1.1rem;
  } 
  }
  h6 {
    font-size: 1.1rem;
  }
  .form-inline {
    justify-content: center;
    .input-group {
      width: 50%;
      @include media-breakpoint-down(md) {
        width: 60%;
      }
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
      .form-control {
        border: 0;
        border-radius: 0;
        background-color: rgba(255,255,255,0.6);
        padding: 2rem 1.65rem;
        @include media-breakpoint-down(md) {
           padding: 2rem 0.5rem;
        }
        @include media-breakpoint-down(sm) {
           padding: 2rem 0.3rem;
            font-size: 0.9rem;
        }
      }
      button,.input-group-text {      
        background-color: rgba(255,255,255,0.6);
        padding: 0.375rem 1.5rem;
        border:0;
      }
      &-append {
        margin-left:0;
      }
      &-prepend {
        margin-right:0;
      }
    }
    svg {
      fill: rgba(0,0,0,0.5);
    }
  }
}