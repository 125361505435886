.roomsContainer {
  background-color: $theme-lightgray;
  padding: 30px 0;
  @include media-breakpoint-down(xs) { 
    padding: 0; 
    .container { padding: 0; }
  }
}
.accordionRooms {
  .card {
    border: none;
    border-radius: 0;
    background-color: transparent;
    margin-bottom: 35px;
  }
  .card-header {
    border: none;
    border-radius: 0;
    height: 120px;
    line-height: 120px;
    padding: 0 30px;
    @include media-breakpoint-down(xs) { padding: 0 15px; }
    button {
      color: $theme-white;
      text-transform: uppercase;
      font-size: 30px;
      font-weight: 900;
      width: 100%;
      text-align: left;
      &:after {
        content: '-';
        float: right;
        font-size: 63px;
        line-height: 35px;
        @include media-breakpoint-down(xs) { font-size: 45px; }
      }
      &.collapsed {
        &:after { content: '+'; }
      }
      @include media-breakpoint-down(xs) { font-size: 24px; }
    }
  }
  .card-body {
    padding: 40px 30px;
    @include media-breakpoint-down(xs) { padding: 30px 15px; }
  }
  &__room {
    margin-bottom: 50px;
    &:last-child { margin-bottom: 0; }
    h3 {
      margin-bottom: 25px;
      font-weight: 600;
      a { 
        color: $theme-gold; 
        font-size: 24px;
      }
    }
    p { font-weight: 300; }
    &__slider {
      width: 100%;
      padding: 0 40px;
      margin-top: 35px;
      .item {
        height: 155px;
        width: 25%;
        margin: 0 10px;
        display: inline-block;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        a {
          display: block;
          width: 100%;
          height: 100%;
          position: relative;
          z-index: 2;
        }
      }
      .slick-prev,
      .slick-next {
        width: 16px;
        height: 24px;
        background-image: url('/images/svgStandAlone/000000/images/svg/left_arrow.svg');
        background-position: center;
        background-repeat: no-repeat;
        &:before { display: none; }
      }
      .slick-prev { 
        left: 0;
      }
      .slick-next {
        right: 0;
        transform: rotate(180deg);
      }
    }
  }
}