.genericSlider {
  widows: 100%;;
  position:relative;
  overflow: hidden;
  padding-bottom: 2rem;
   @include media-breakpoint-down(sm) {
    padding:0;
  }
  &__slider {
    height: inherit;
    position:relative;
    &__item {
      width:100%;
      height: 650px;
      background-repeat:no-repeat;
      background-position: center center;
      background-size: cover;
      position:relative;
      @include media-breakpoint-down(sm) {
        height: 350px;
      }
      &__in {
        width:100%;
        height: 650px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;
        padding-bottom: 1rem;
        /*background: -moz-linear-gradient(top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.77) 1%, rgba(0,0,0,0) 25%, rgba(0,0,0,0) 75%, rgba(0,0,0,0.8) 100%); /* FF3.6-15 */
        /*background: -webkit-linear-gradient(top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.77) 1%,rgba(0,0,0,0) 25%,rgba(0,0,0,0) 75%,rgba(0,0,0,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
        /*background: linear-gradient(to bottom, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.77) 1%,rgba(0,0,0,0) 25%,rgba(0,0,0,0) 75%,rgba(0,0,0,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        /*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc000000', endColorstr='#cc000000',GradientType=0 );*/
        @include media-breakpoint-down(sm) {
          height: 350px;
        }
         /* IE6-9 */
      }
    }
    &__caption {
      font-size:2.3rem;
      font-weight: $theme-font-weight-normal;
      color: $theme-white;
      text-transform: uppercase;
      text-align: center;
      @include media-breakpoint-down(sm) {
        font-size:1.7rem;
        margin-bottom: 5rem;
      }
    }
    &__trip {
      margin-top:2rem;
      margin-bottom:1rem;
    }
  }
  .slick-arrow {
    color: $theme-white;
    &::before {
      display: none;
    }
  }
  .slick-next {
    width: 60px;
    height: 60px;
    right:0px;
    bottom: -30px;
    left:auto;
    z-index: 5;
    background-color: $theme-green-dark;
    transition: all 0.3s ease;
    &:hover {
      opacity: 1;
    }
  }
  .slick-prev {
    width: 60px;
    height: 60px;
    bottom: -30px;
    left: 0;
    z-index: 5;
    background-color: $theme-green-medium;
    &:hover {
      opacity: 1;
    }
    img {
      transform: rotate(180deg);
    }
  }
}