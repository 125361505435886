.menuOverlay {
  position:fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100vh;
  background-color: $theme-menu-background;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: all 0.2s ease;
  transform: translateY(-100%);
  opacity:0;
  z-index: 100;
  border: 10px solid $theme-white;
  @include media-breakpoint-up(sm) { 
    justify-content: center;
    
  }
  @include media-breakpoint-down(sm) { 
    padding-top: 100px;
  }
  .menu_open & {
    opacity:1;
    transform: translateY(0);
  }
  ul {
    list-style: none;
    font-size: 1.3rem;
    padding-left: 0;
  }
  a {
    font-size: 1.3rem;
    color: $theme-white;
  }
  > ul {
    margin-left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 90%;
    > li {
      margin: 10px 0;
      display: flex;
      @include media-breakpoint-down(sm) { 
        flex-direction: column;
      }
      > a {
        display: flex;
        margin-right:15px;
        text-transform: uppercase;
      }
      > ul {
        opacity:0;
        padding-left: 0;
        position: absolute;
        left:100vw;
        transition: all 0.3s ease;
        @include media-breakpoint-down(sm) { 
          position: initial;
          height: 0;
          opacity:1;
          overflow: hidden;
        }
      }
    }
    > li.has_sub {
      position: relative;
      > a {
        &:after {
          content:'';
          display: block;
          width: 0;
          height: 1px;
          background-color: $theme-white;
          margin:14px 0 0 15px;
          transition: all 0.2s ease;
        }
        &:hover {
          &:after {
            width: 30px;
          }
        }
        &.active {
          &:after {
            width: 300px;
          }
        }
      }
      &.active {
        width: 100%;
        ul {
          height: auto;
          @include media-breakpoint-up(xs) { 
            opacity:1;
            left:395px;
          }
        }
      }
    }
  }
}