.homeRooms {
  background-color: $theme-lightgray;
  min-height: 300px;
  margin: 0 10px 10px 10px;
  .discover_more {
    color: $theme-font-base-color !important;
  }
  h4 {
    color: #6a737b;
    font-size: 1.3rem;
    text-transform: uppercase;
    @include media-breakpoint-down(sm) {
      font-size: 1.1rem;
    } 
  }
  &__item {
    $itemclass: &; 
    position: relative;
    &__in {
      width: 100%;
      height: 400px;
      position: relative;
      overflow: hidden;
      margin:0 auto;
      &__overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        transition: all 0.2s ease;   
      }
      &__discover {
        position: absolute;
        left:0;
        bottom:0;
        width: 100%;
        height: 70px;
        background-color: $theme-bluette;
        transform: translate(0,100%); 
        transition: all 0.2s ease;   
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: flex-end;
      }
      &:before {
        @extend .hoverize_this_1;
      }
      &:hover:before {
        transform: translateX(0);
      }
      &:hover {
        #{$itemclass}__in__discover {
          transform: translate(0,0);
        }
      }
    }
    &__title {
      display: block;
      position: absolute;
      top:100%;
      left: 50%;
      font-size: 3rem;
      line-height: 3rem;
      @include media-breakpoint-down(sm) {
        font-size: 2.4rem;
        line-height: 2.5rem;
      }
      transform: rotate(-90deg) translate(50px,-50%);
      transform-origin: top left;
      color: $theme-white;
      z-index: 6;
      font-family: $theme-font-josefin;
      &:hover {
        color: $theme-white;
        text-decoration: none;
      }
    }
  }
}