.homeVisual,
.homeGallery {
  width:$visual-width;
  height: 100vh;
  height: $visual-height;
  position:relative;
  overflow: hidden;
  margin:10px 0 0 10px;
  .scrolled & {
    margin-top:0;
  }
  &__img {
		position:absolute;
		top:50%;
		left:50%;
		max-width: 100%;
		transform: translate(-50%, -50%);
	}
   @include media-breakpoint-down(sm) {
    padding:0;
    height: 445px;
  }
  &__slider {
    height: 100vh;
    height: $visual-height;
    position:relative;
    @include media-breakpoint-down(sm) {
      height: 445px;
    }
    &__item {
      width:100%;
      height: 100vh;
      height: $visual-height;
      background-repeat:no-repeat;
      background-position: center center;
      position:relative;
      @include media-breakpoint-down(sm) {
        height: 445px;
      }
      &__in {
        width:100%;
        height: 100vh;
        height: $visual-height;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;
        padding-bottom: 1rem;
        background: -moz-linear-gradient(top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.77) 1%, rgba(0,0,0,0) 25%, rgba(0,0,0,0) 75%, rgba(0,0,0,0.8) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.77) 1%,rgba(0,0,0,0) 25%,rgba(0,0,0,0) 75%,rgba(0,0,0,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.77) 1%,rgba(0,0,0,0) 25%,rgba(0,0,0,0) 75%,rgba(0,0,0,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc000000', endColorstr='#cc000000',GradientType=0 ); /* IE6-9 */
        @include media-breakpoint-down(sm) {
          height: 445px;
        }
      }
    }
    &__caption {
      font-size:2.3rem;
      font-weight: $theme-font-weight-normal;
      color: $theme-white;
      text-transform: uppercase;
      text-align: center;
      text-shadow: 2px 2px 2px rgba(0,0,0,0.5);
      @include media-breakpoint-down(sm) {
        font-size:1.5rem;
        padding: 5px;
        line-height: 2rem;
      }
    }
    &__trip {
      margin-top:2rem;
      margin-bottom:1rem;
      img { display: inline-block; }
    }
  }
  .slick-arrow {
    color: $theme-white;
    background-image: url(/images/svg/slick-arrow.svg);
    background-position: center center;
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    background-color: rgba(0,0,0,0.5);
    padding: 20px;
    border-radius: 20px;
    &::before {
      display: none;
    }
    @include media-breakpoint-down(xs) { display: none!important; }
  }
  .slick-next {
    right:50px;
    top:50%;
    transform: translateY(50%);
    z-index: 5;
    @include media-breakpoint-down(sm) {
      bottom:20px;
      right:40px;
      top:45%;
    }
  }
  .slick-prev {
    left:50px;
    top:50%;
    transform: translateY(50%) rotate(-180deg);
    z-index: 5;
    @include media-breakpoint-down(sm) {
      bottom:20px;
      left:10px;
      top:45%;
    }
  }
}

.homeGallery{
  height: $gallery-height;
  &__slider {
    height: $gallery-height;
    &__item {
      height: $gallery-height;
      &__in {
        height: $gallery-height;
      }
    }
  }
}

.videoContainer{
  video{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  height: 100vh;
  width: 100%;
  //mobile height
  @include media-breakpoint-down(sm) {
    height: 40vh;
  }
}