.mainText {
  margin:0 10px 0 10px;
  padding-top: 3rem;
  padding-bottom: 2rem;
  @include media-breakpoint-down(sm) {
    padding-top: 1.5rem;
  }
  .t_page_home & {
    margin:10px;
  }
  h1 {
    color: #5f686d;
    font-weight: $theme-font-weight-bold;
    font-size: 2.5rem;
    letter-spacing: 0.5rem;
    @include media-breakpoint-down(sm) {
      font-size: 1.5rem;
    }
  }
  h3 {
    color: #5f686d;
    font-weight: $theme-font-weight-bold;
    font-size: 1.8rem;
    letter-spacing: 0.5rem;
      @include media-breakpoint-down(sm) {
      font-size: 1.3rem;
    }
  }
  h4 {
    font-family: $theme-font-josefin;
    color: $theme-font-base-color;
    font-size: 1.3rem;
    @include media-breakpoint-down(sm) {
      font-size: 1.1rem;
    } 
  }
  h6 {
    font-size: 1.1rem;
  }
  .discover_more {
    color: $theme-font-base-color;
    .material-icons {
      font-size: 2rem;
    }
  }
}